import { Component, OnInit, Inject } from '@angular/core';
import { paymentFields } from '@modules/tour/booked/payment-dialog/payment-dialog.model';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
})
export class PaymentDialogComponent implements OnInit {
  public fields: FormlyFieldConfig[];
  public form: FormGroup;
  public model: any;
  public options: FormlyFormOptions;

  public isLoading: boolean;
  public hiddenDialog: boolean;
  public hiddenRadio: boolean;
  public checked: boolean;

  public option: number;
  public brand: string;
  public cardLast: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public paymentRef: MatDialogRef<PaymentDialogComponent>,
    private _api: ApiService,
    private _toastr: ToastrService,
    public _route: ActivatedRoute,
    public _router: Router
  ) {
    this.fields = paymentFields;
    this.form = new FormGroup({});
    this.model = {};
    this.options = {};

    this.isLoading = false;
    this.hiddenDialog = false;
    this.hiddenRadio = false;
    this.checked = true;
    this.option = 0;
    this.set();
    this.getData();
  }

  public set() {
    this.fields[0].templateOptions.keyup = e => {
      this.model.cardNumber = this.model.cardNumber
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
      this.fields[0].formControl.setValue(this.model.cardNumber);
    };
  }

  public getData() {
    if (this.data.other == 1) {
      this.checked = false;
      this.hiddenRadio = true;
      this.option = 1;
    } else {
      this.hiddenRadio = false;
    }

      this.brand = this.data.brand;
      this.cardLast = this.data.cardLast;
  }

  public close() {
    this.paymentRef.close();
  }

  public payRemaining() {
    const data = {
      id: this.data.id,
      other: this.option,
      card: this.model
    };

    if (this.option === 1 && !this.form.valid) {
      return;
    }

    this.postData(data);
  }

  public postData(data: any) {
    this.isLoading = true;
    this._api.post('api/payment/remaining', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.isLoading = false;
        this.hiddenDialog = true;
      } else {
        this.isLoading = false;
        this._toastr.warning(res.message);
      }
    });
  }

  ngOnInit() {
  }

}
