import { Component, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ApiService } from '@services/api.service';
import { TourDetailService } from '@modules/tour/detail/tour-detail.service';
import { TourService } from '@modules/tour/tour.service';
import * as moment from 'moment';

const DatepickerFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
 };

 const DatepickerProviders = [
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: DatepickerFormats
  }
 ];

@Component({
  selector: 'app-tour-booking-bar',
  templateUrl: './tour-booking-bar.component.html',
  styleUrls: ['./tour-booking-bar.component.scss'],
  providers: DatepickerProviders
})
export class TourBookingBarComponent implements OnInit {
  public deposit: number;
  public depositDay: number;
  public minDate: any;

  constructor(
    private _api: ApiService,
    public _detail: TourDetailService,
    public _tour: TourService) {
    this.getDeposit();
    this.getDepositDay();
    this.minDate = moment().format();
  }

  public getDeposit() {
    this._api.get('api/data/setting/deposit').subscribe(res => {
      if (res !== false) {
        this.deposit = +res;
      } else {
        this.deposit = 15;
      }
    });
  }

  public getDepositDay() {
    this._api.get('api/data/setting/day_deposit').subscribe(res => {
      if (res !== false) {
        this.depositDay = +res;
      } else {
        this.depositDay = 30;
      }
  });
}

  ngOnInit() {
    this._detail.init();
    this._detail.createForm();
  }

  public filter() {
    return this._detail.priceTypeRiders.filter(tour => {
      if (+this._tour.data[tour.price] > 0.00) {
        return true;
      }

      return false;
    });
  }
}
