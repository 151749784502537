import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HomeHeroService } from './home-hero.service';

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeHeroComponent implements OnInit {

  constructor(public _hero: HomeHeroService) { }

  ngOnInit() { }
}
