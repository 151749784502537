import { Component, OnInit } from '@angular/core';
import { MetaTagService } from '@services/meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private _meta: MetaTagService) {
    window.scrollTo(0, 0);
    this._meta.set('home');
    window.scrollTo(0, 0);
  }

  ngOnInit() { }

}
