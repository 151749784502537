import { FormlyFieldConfig } from '@ngx-formly/core';
import { validation } from '@models/validation';

export interface NewTour {
  name: string;
  email: string;
  phone: string;
  startDate: string;
  endDate: string;
  persons: number;
  note: string;
}

export const newTourModel: NewTour = {
  name: '',
  email: '',
  phone: '',
  startDate: '',
  endDate: '',
  persons: 4,
  note: ''
};

export const newTourFields: FormlyFieldConfig[] = [
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-1',
        key: 'name',
        type: 'input',
        templateOptions: {
          label: 'Name',
          required: true
        }
      },
      {
        className: 'flex-1',
        key: 'phone',
        type: 'input',
        templateOptions: {
          label: 'Phone',
          required: true
        }
      }
    ]
  },
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-2',
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'email',
          label: 'Email',
          required: true
        },
        validators: {
          email: validation.email
        }
      },
      {
        className: 'flex-2',
        key: 'number',
        type: 'input',
        defaultValue: 4,
        templateOptions: {
          type: 'number',
          label: 'Number of People (Min: 4, Max: 14)',
          required: true,
          min: 4,
          max: 14
        }
      }
    ]
  },
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-1',
        key: 'start_date',
        type: 'input',
        templateOptions: {
          type: 'date',
          label: 'Start Date',
          required: true
        },
        validators: {
          startDate: validation.startDate
        }
      },
      {
        className: 'flex-1',
        key: 'end_date',
        type: 'input',
        templateOptions: {
          type: 'date',
          label: 'End Date',
          required: true
        },
        validators: {
          endDate: validation.endDate
        }
      }
    ]
  },
  {
    key: 'note',
    type: 'textarea',
    templateOptions: {
      label: 'Note',
      maxRows: 2,
    }
  }
];
