import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { PaymentService } from '@modules/checkout/payment/payment.service';
import { SettingsService } from '@services/settings.service';
import { MetaTagService } from '@services/meta.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit, OnDestroy {
  constructor(
    public _payment: PaymentService,
    public _setting: SettingsService,
    private _meta: MetaTagService
  ) {
    window.scrollTo(0, 0);
    this._setting.isShowFooter = false;
    this._setting.isShowHeader = false;
    this._meta.set('checkout');
  }

  ngOnInit() {
    this._payment.init();
  }

  birthdayDiscount() {
    const total: number = this._payment.totalPrice;
    const rider = parseInt(this._payment.data.booking.price_normal);
    return total + rider;
  }

  ngOnDestroy() {
    this._setting.isShowFooter = true;
    this._setting.isShowHeader = true;
  }
}
