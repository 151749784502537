import { Injectable, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormlyFormOptions } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';

import {
  paymentFields,
  dataTable,
  columnsFixPrice,
  columnsNotFixPrice
} from '@modules/checkout/payment/payment.model';

import { ApiService } from '@services/api.service';
import { SettingsService } from '@services/settings.service';
import * as moment from 'moment';
@Injectable()
export class PaymentService {
  public dialogRef: MatDialogRef<DialogComponent>;
  public fields: any;
  public form = new FormGroup({});
  public model: any;
  public options: FormlyFormOptions;

  public dataSource: any = {};
  public dataTable = dataTable;
  public displayedColumns: any;

  public isLoading: boolean;
  public showDepositPayment: boolean;
  public checkedRadio: boolean;

  public code: number;
  public data: any;
  public dateTour: string;
  public deposit: any;
  public depositDays: any;
  public formatDate: any;
  public nameTour: string;
  public paymentType: number;
  public totalPrice: number;
  public tourTotalPrice: number;
  public surcharge: number;
  public duration: any;
  public fee: number;
  public payRemaining: number;

  public isBirthday: boolean;

  private paymentAPI = 'api/payment';

  constructor(
    @Inject(LOCAL_STORAGE) private _storage: WebStorageService,
    private _api: ApiService,
    private _dialog: MatDialog,
    private _location: Location,
    private _router: Router,
    private _toastr: ToastrService,
    public _setting: SettingsService
  ) {
    this.code = 0;
    this.paymentType = 0;
    this.dateTour = '';
    this.nameTour = '';

    this.isLoading = true;

    this.dataSource = [];
    this.model = {};
    this.options = {};
    this.totalPrice = 0;
    this.tourTotalPrice = 0;
    this.checkedRadio = false;
  }

  public getColumsTable() {
    if (this.data.booking.fix_price == 0) {
      this.displayedColumns = columnsNotFixPrice;
    } else {
      this.displayedColumns = columnsFixPrice;
    }
  }

  public set() {
    this.fields[2].templateOptions.keyup = e => {
      this.model.cardNumber = this.model.cardNumber
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
      this.fields[2].formControl.setValue(this.model.cardNumber);
    };
  }

  public getDepositSetting() {
    this._api.get('api/data/setting/deposit').subscribe(res => {
      if (res !== false) {
        this.deposit = +res;
      } else {
        this.deposit = 15;
      }
    });
  }

  public showPaymentType() {
    if (this.data.booking.slug.indexOf('birthday') > -1) {
      this.isBirthday = true;
    }

    if (this.data.booking.type_tour == 0) {
      this.formatDate = this.data.dates.filter((item: any) => item.fullDate === this.dateTour);
      this.duration = (moment(this.formatDate[0].sortDate, 'Do MMMM YYYY').valueOf() - moment().valueOf());
    } else {
      this.duration = (moment(this.data.booking.date, 'YYYY MM DD').valueOf() - moment().valueOf());
    }

    const days = Math.floor(this.duration / 86400000) + 1;

    this._api.get('api/data/setting/day_deposit').subscribe(res => {
      if (res !== false) {
        this.depositDays = +res;
      } else {
        this.depositDays = 30;
      }

      if (days < this.depositDays) {
        this.showDepositPayment = true;
        this.paymentType = 1;
        this.payRemaining = 1;
      } else {
        this.showDepositPayment = false;
        this.paymentType = 0;
        this.payRemaining = 0;
      }

    });
  }

  public init() {
    this.payRemaining = 0;
    this.fields = paymentFields;
    this.form = new FormGroup({});
    this.model = { checked: true };
    this.options = {};
    this.showDepositPayment = true;
    this.checkedRadio = false;
    this.set();
    this.getDepositSetting();
    this.data = JSON.parse(sessionStorage.getItem(this._setting.idTab));

    if (this.data == null) {
      this._router.navigate(['/']);
    } else {
      this.getColumsTable();
      this.code = this.data.booking.code;
      this.dataSource = this.data.riders;
      if (this.data.booking.type_tour == 1) {
        this.dateTour = moment(this.data.booking.date).format('Do MMMM YYYY');
      } else {
        this.dateTour = this.data.booking.date;
      }

      this.nameTour = this.data.booking.name;
      this._api.get('api/data/setting/fee').subscribe(res => {
        if (res !== false) {
          this.fee = +res;
          this.surcharge = (+this.data.totalPrice.totalSale * this.fee) / 100;
          this.totalPrice = +this.data.totalPrice.totalSale + this.surcharge;
          this.tourTotalPrice = this.data.totalPrice.totalSale;
        } else {
          this.surcharge = (+this.data.totalPrice.totalSale * 2.9) / 100;
          this.totalPrice = +this.data.totalPrice.totalSale + this.surcharge;
          this.tourTotalPrice = this.data.totalPrice.totalSale;
        }
      });
      this.totalPrice = this.data.totalPrice.totalSale;

      this.showPaymentType();
    }
  }

  public back() {
    this._location.back();
  }

  public submit() {
    console.log(this.data);
    this.isLoading = false;

    Object.assign(this.data, {
      card: this.model
    });

    Object.assign(this.data.totalPrice, {
      typePayment: this.paymentType
    });
    let date = this.data.booking.date;

    if (this.data.booking.type_tour == 1) {
      this.data.booking.date = moment(this.data.booking.date).format('Do MMMM YYYY');
    }
    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));

    return this._api.post(this.paymentAPI, this.data).subscribe(
      (res: any) => {
        this.isLoading = true;
        this.openDialog(res.Data.order.id);
        this.removeData();
        this.model = {};
        this.options.resetModel();
        this.form.reset();
      },
      error => {
        this.data.booking.date = date;
        sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
        this.isLoading = true;
        this._toastr.warning(error.error.Message);
      }
    );
  }

  public radioChange(event: any) {
    const feeFull = (+this.data.totalPrice.totalSale * this.fee) / 100;
    const feeDeposit = ((+this.data.totalPrice.totalSale * this.deposit / 100) * this.fee) / 100;
    if (event.value == 1) {
      this.payRemaining = 1;
      this.surcharge = feeFull;
    } else {
      this.payRemaining = 0;
      this.surcharge = feeDeposit;
    }
  }

  public openDialog(id: any) {
    this.dialogRef = this._dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        deposit: this.deposit,
        totalPrice: this.totalPrice
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._router.navigate(['/book', id, this.data.booking.slug]);
      }
    });
  }

  public removeData() {
    this._storage.remove(this._setting.idTab);
  }
}
