import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public menuOpen = false;
  public menuLinks = [
    { name: 'home', route: '/' },
    { name: 'tours', route: '/tours' },
    // { name: 'tours', route: '/calendar' },
    { name: 'about', route: '/page/about' },
    { name: 'contact', route: '/page/contact' }
  ];

  constructor() {}

  ngOnInit() {}

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
