import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';

import {
  Contact,
  contactModel,
  contactFields
} from '@modules/page/contact/contact.model';
import { FormlyFormOptions } from '@ngx-formly/core';

@Injectable()
export class ContactService {
  public fields = contactFields;
  public form = new FormGroup({});
  public model: Contact;
  public options: FormlyFormOptions = {};

  public isLoading: boolean;

  private url = 'api/contact/contact';

  constructor(private toastr: ToastrService, private _api: ApiService) {
    this.isLoading = false;
  }

  public set() {
    this.fields = contactFields;
    this.form = new FormGroup({});
    this.model = contactModel;
    this.options = {};
  }

  public submit() {
    if (this.form.valid) {
      this.isLoading = true;
      this._api.post(this.url, this.model).subscribe(
        () => {
          this.isLoading = false;
          this.toastr.success('Send Message Successfully!');
          this.form.reset();
          this.model = contactModel;
          this.options.resetModel();
        },
        () => {
          this.toastr.success('Send Message Failed!');
        }
      );
    }
  }
}
