import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public _setting: SettingsService) {}

  ngOnInit() {
    this._setting.checkIdTab();
    this._setting.loadScript(
      'https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=516&amp;locationId=12931053&amp;lang=en_AU&amp;rating=true&amp;nreviews=4&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=true&amp;border=false&amp;display_version=2'
    );
  }
}
