import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {ComponentsModule} from '@components/components.module';
import {
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from '@modules/home/home.component';
import {HomeHeroComponent} from '@modules/home/hero/home-hero.component';
import {HomeReviewsComponent} from '@modules/home/reviews/home-reviews.component';
import {HomeBirthdayComponent} from '@modules/home/birthday/home-birthday.component';
import {HomePromoComponent} from '@modules/home/promo/home-promo.component';
import {HomeBikesComponent} from '@modules/home/bikes/home-bikes.component';
import {HomeGuidesComponent} from '@modules/home/guides/home-guides.component';

import {MetaTagService} from '@services/meta.service';
import {HomeHeroService} from '@modules/home/hero/home-hero.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ComponentsModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatOptionModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        SatNativeDateModule

    ],
    providers: [MetaTagService, HomeHeroService],
    exports: [],
    declarations: [
        HomeComponent,
        HomeHeroComponent,
        HomeReviewsComponent,
        HomeBirthdayComponent,
        HomePromoComponent,
        HomeBikesComponent,
        HomeGuidesComponent
    ]
})
export class HomeModule {
}
