import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  MatIconModule,
  MatCardModule,
  MatDialogModule,
  MatButtonModule
} from '@angular/material';

import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { DialogComponent } from './dialog/dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    DialogComponent
  ],
  exports: [HeaderComponent, FooterComponent, LoaderComponent, DialogComponent],
  entryComponents: [DialogComponent]
})
export class ComponentsModule {}
