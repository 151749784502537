import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

import { ApiService } from '@services/api.service';
import { SettingsService } from '@services/settings.service';

import * as moment from 'moment';

import { MatDialog, MatDialogRef } from '@angular/material';
import { NewTourDialogComponent } from '@modules/tour/list/new-tour-dialog/new-tour-dialog.component';
import { Router } from '@angular/router';

@Injectable()
export class TourService {
  public dialogRef: MatDialogRef<NewTourDialogComponent>;
  public content: any;
  public data: any;
  public id;
  public isBirthday: boolean;
  public stats: Array<any>;

  public arrDates: any;
  public dates: any;
  public dataLocal: any;
  public fullDate: any;
  public sortDate: any;
  public arrMonth = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];

  constructor(
    @Inject(LOCAL_STORAGE) private _storage: WebStorageService,
    private _api: ApiService,
    private _dialog: MatDialog,
    private _router: Router,
    private _title: Title,
    public _setting: SettingsService
  ) {
    this.data = {};
  }

  public init(res: any) {
    this.data = res;
    if (+res.type_tour === 1) {
      this.isBirthday = true;
      this.arrDates = [];
    } else {
      this.arrDates = this.data.dates;
      this.formatDates(this.arrDates);
      this.isBirthday = false;
    }

    this.content = this.data.item.description;
    this._title.setTitle(this.data.item.title);

    let hiddenDeparture = true;
    let hiddenArrival = true;
    if (this.data.departure_airport) {
      hiddenDeparture = false;
    }

    if (this.data.arrival_airport) {
      hiddenArrival = false;
    }

    this.stats = [
      { label: 'Days', icon: 'date_range', stat: this.data.duration, hidden: false },
      { label: 'Cities', icon: 'location_city', stat: this.data.cities, hidden: false },
      { label: 'KM', icon: 'motorcycle', stat: this.data.distance, hidden: false },
      { label: '', icon: 'flight_land', stat: this.data.arrival_airport, hidden: hiddenArrival },
      { label: '', icon: 'flight_takeoff', stat: this.data.departure_airport, hidden: hiddenArrival }
    ];
  }

  public formatDates(arrDates: any) {
    arrDates.forEach(date => {
      this.fullDate = moment({
        year: moment().year(),
        month: this.arrMonth.indexOf(date.month),
        day: date.date
      });


      if (this.fullDate < moment()) {
        this.sortDate =
          moment(this.fullDate).format('DD MMM') + ' ' + (moment().year() + 1);

        this.fullDate =
          moment(this.fullDate).format('Do MMMM') + ' ' + (moment().year() + 1);
      } else {
        this.sortDate = moment(this.fullDate).format('DD MMM YYYY');
        this.fullDate = moment(this.fullDate).format('Do MMMM YYYY');
      }

      Object.assign(date, {
        fullDate: this.fullDate,
        sortDate: this.sortDate
      });
    });

    this.dates = arrDates;

    this.dates.forEach(item => {
      this.dates.push({
        date: item.date,
        fullDate: moment(item.fullDate, 'Do MMMM YYYY').add(1, 'year').format('Do MMMM YYYY'),
        sortDate: moment(item.sortDate, 'DD MMM YYYY').add(1, 'year').format('DD MMM YYYY')
      });
    });

    this.dates.forEach(item => {
      this._api.post('api/data/count-order-date', {
        date: item.fullDate,
        item_id: this.data.item_id
      }).subscribe((res: any) => {

        let disabled: boolean;
        if (res >= this.data.limit_rider && this.data.limit_rider !== null) {
          disabled = true;
        } else {
          disabled = false;
        }
        Object.assign(item, {
          disabled: disabled,
          booked: +res
        });
      });
    });

    this.sortDates(this.dates);
    return this.dates;
  }

  public sortDates(arr: any) {
    arr.sort(function (prev, last) {
      if (moment(prev.sortDate).valueOf() < moment(last.sortDate).valueOf()) {
        return -1;
      } else if (
        moment(prev.sortDate).valueOf() > moment(last.sortDate).valueOf()
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public createNewTour() {
    this.dialogRef = this._dialog.open(NewTourDialogComponent, {
      panelClass: 'dialog',
      maxWidth: '100%',
      width: '550px',
      height: '100vh',
    });
  }

  public bookTour($id) {
    this._api.get('api/tours/detailtour', $id).subscribe((res: any) => {
      const dates = this.formatDates(res.dates);
      sessionStorage.setItem(this._setting.idTab, JSON.stringify({
        booking: {
          id: res.item_id,
          name: res.item.title,
          code: '',
          date: '',
          price_driver: res.price_driver,
          price_nondriver: res.price_nondriver,
          price_normal: res.price_normal,
          promotion: ''
        },
        dates: dates
      }));
      this._router.navigate(['/riders']);
    });
  }
}
