import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { ApiService } from '@services/api.service';

@Injectable()
export class MetaTagService {
  private hostUrl;

  private url = 'api/data/metatag';

  constructor(
    private _api: ApiService,
    private meta: Meta,
    private title: Title
  ) {
    this.hostUrl = window.location.host;
  }

  public setPage(options: any) {
    const img = `${this.hostUrl}/uploads/${options.image}`;
    const tags = {
      name: [
        ['keywords', options.keywords],
        ['image', img],
        ['description', options.description]
      ],
      property: [
        ['og:title', options.title],
        ['og:description', options.description],
        ['og:image', img],
        ['og:url', options.url],
        ['og:site_name', 'LBMT'],
        ['og:locale', 'en_US'],
        ['og:type', 'website']
      ]
    };

    this.title.setTitle(options.title);

    for (const type in tags) {
      if (tags[type]) {
        tags[type].forEach((tag: Array<string>) => {
          this.updateTag(type, tag[0], tag[1]);
        });
      }
    }
  }

  public set(page: string) {
    this._api.get(this.url, page).subscribe((res: any) => {
      this.setPage({
        title: res.title,
        description: res.description,
        image: res.image,
        keywords: res.keywords,
        defaultURL: res.defaultURL || window.location.href
      });
    });
  }

  private updateTag(type: string, key: string, value: string) {
    const data: any = {};
    data[type] = key;
    data.content = value;
    this.meta.updateTag(data);
  }
}
