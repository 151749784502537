import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public footerLinks = [
    { name: 'home', route: '/' },
    { name: 'tours', route: '/tours' },
    { name: 'about', route: '/page/about' },
    { name: 'contact', route: '/page/contact' },
    { name: 'tour information', route: '/page/tour-information' },
    { name: 'licensing & legal', route: '/page/licensing-legal' },
    { name: 'booking & charges', route: '/page/booking-charges' }
  ];

  constructor() { }

  ngOnInit() { }
}
