import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatFormFieldModule, MatButtonModule } from '@angular/material';
import { ComponentsModule } from '@components/components.module';

import { PageService } from '@modules/page/page.service';
import { ContactService } from '@modules/page/contact/contact.service';
import { SettingsService } from '@services/settings.service';

import { PageComponent } from '@modules/page/page.component';
import { ContactComponent } from '@modules/page/contact/contact.component';
import { NotFoundComponent } from '@modules/page/not-found/not-found.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    ComponentsModule,

    MatFormFieldModule,
    MatButtonModule,
  ],
  providers: [PageService, ContactService, SettingsService],
  declarations: [PageComponent, ContactComponent, NotFoundComponent]
})
export class PageModule { }
