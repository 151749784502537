import { FormlyFieldConfig } from '@ngx-formly/core';
import { validation } from '@models/validation';

export interface Rider {
  action: string;
  address: string;
  email: string;
  firstname: string;
  fullname: string;
  gender: string;
  lastname: string;
  phone: string;
  price: string;
  return_rider: number;
  t_shirt: string;
  type: string;
}

export const ridersModel: Rider = {
  action: '',
  address: '',
  email: '',
  firstname: '',
  fullname: '',
  gender: '',
  lastname: '',
  phone: '',
  price: '',
  return_rider: 0,
  t_shirt: '',
  type: ''
};

export const dataTable = [
  {
    matColumnDef: 'fullname',
    title: 'Name'
  },
  {
    matColumnDef: 'type',
    title: 'Type Rider'
  },
  {
    matColumnDef: 'return_rider',
    title: 'Return Rider'
  },
  {
    matColumnDef: 'price',
    title: 'Price'
  },
  {
    matColumnDef: 'phone_number',
    title: 'Phone'
  },
  {
    matColumnDef: 't_shirt',
    title: 'T-shirt'
  },
];

export const columnsFixPrice: string[] = [
  'fullname',
  'type',
  't_shirt',
  'price',
  'action'
];

export const columnsNotFixPrice: string[] = [
  'fullname',
  'type',
  'return_rider',
  'price',
  'action'
];

export const ridersFields: FormlyFieldConfig[] = [
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-1',
        key: 'firstname',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'First Name',
          required: true
        }
      },
      {
        className: 'flex-1',
        key: 'lastname',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'Last Name',
          required: true
        }
      }
    ]
  },
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-1',
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'Email',
          required: true
        },
        validators: {
          email: validation.email
        }
      },
      {
        className: 'flex-1',
        key: 'phone_number',
        type: 'input',
        defaultValue: '',
        templateOptions: {
          type: 'text',
          label: 'Phone'
        }
      }
    ]
  },
  {
    key: 'address',
    type: 'input',
    defaultValue: '',
    templateOptions: {
      type: 'text',
      label: 'Address'
    }
  },
  {
    key: 'type',
    type: 'select',
    templateOptions: {
      label: 'Tour Type',
      options: [],
      required: true
    }
  },
  {
    key: 'gender',
    type: 'radio',
    defaultValue: 'Male',
    templateOptions: {
      label: 'Gender',
      options: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'FeMale' }
      ],
      required: true
    }
  },
  {
    key: 't_shirt',
    type: 'select',
    defaultValue: '',
    templateOptions: {
      label: 'T-Shirt Size',
      options: []
    }
  },
  {
    key: 'shirt_name',
    type: 'input',
    defaultValue: '',
    templateOptions: {
      type: 'text',
      label: 'Name On T-shirt'
    }
  }
];
