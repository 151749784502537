import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-guides',
  templateUrl: './home-guides.component.html',
  styleUrls: ['./home-guides.component.scss']
})
export class HomeGuidesComponent implements OnInit {
  public guides = [1, 2, 3];

  constructor() {}

  ngOnInit() {}
}
