import { Component, OnInit } from '@angular/core';

import { TourService } from '@modules/tour/tour.service';

@Component({
  selector: 'app-tour-dates',
  templateUrl: './tour-dates.component.html',
  styleUrls: ['./tour-dates.component.scss']
})
export class TourDatesComponent implements OnInit {
  constructor(public _tour: TourService) {}

  ngOnInit() {}

  public checkDate(month: string) {
    if (!this._tour.data) {
      return '--';
    }

    const date =
      this._tour.data['dates'].find(item => item['month'] === month) || '--';

    return date['date'] === undefined ? '--' : date['date'];
  }
}
