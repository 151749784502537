import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';

import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogComponent } from '@components/dialog/dialog.component';

import {
  Rider,
  dataTable,
  columnsFixPrice,
  columnsNotFixPrice,
  ridersFields
} from '@modules/checkout/riders/riders.model';

import { FormlyFormOptions } from '@ngx-formly/core';

import { ApiService } from '@services/api.service';
import { SettingsService } from '@services/settings.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RidersService {
  public dialogRef: MatDialogRef<DialogComponent>;
  public isPayment: boolean;
  public showBtn: boolean;
  public turnOffBlur: boolean;
  public isBirthday: boolean;
  public isLoading: boolean;

  public arrDates: any = [];
  public dateTour: any;
  public nameTour: string;
  public promotion: string;
  public tshirt: any;
  public soloTypeLabel: string;

  public fields: any;
  public form: FormGroup;
  public formBooking: FormGroup;
  public model: any;
  public options: FormlyFormOptions;

  public data: any;
  public deposit: any;
  public riders: any = [];
  public riderFields = [];

  public totalPrice: number;
  public returnRider: number;
  public typePrice: number;
  public fixPrice: number;

  public current: number = 0;
  public max: number = 0;
  public booked: number = 0;
  public col: number = 0;

  public dataSource = new MatTableDataSource<Rider[]>(this.riders);
  public dataTable = dataTable;
  public displayedColumns: any;

  private apiPromotion = 'api/tours/checkpromotioncode';
  private getTshirtAPI = 'api/data/lbmttshirts';

  constructor(
    @Inject(LOCAL_STORAGE) private _storage: WebStorageService,
    private _api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _location: Location,
    public _setting: SettingsService
  ) {
    this.dateTour = '';
    this.nameTour = '';
    this.promotion = '';
    this.totalPrice = 0;
    this.returnRider = 0;
    this.fixPrice = 0;

    this.getTshirt();
  }

  public getColumsTable() {
    if (this.data.booking.fix_price == 0) {
      this.displayedColumns = columnsNotFixPrice;
    } else {
      this.displayedColumns = columnsFixPrice;
    }
  }

  public getDepositSetting() {
    this._api.get('api/data/setting/deposit').subscribe(res => {
      if (res !== false) {
        this.deposit = +res;
      } else {
        this.deposit = 15;
      }
    });
  }

  public set() {
    this.getDepositSetting();

    this.showBtn = false;
    this.isLoading = false;
    this.fields = ridersFields;
    this.form = new FormGroup({});
    this.model = {};
    this.options = {};
    this.turnOffBlur = false;
    this.typePrice = 0;
    this.isPayment = true;
    this.col = 0;

    this.data = JSON.parse(sessionStorage.getItem(this._setting.idTab));
    if (this.data !== null) {
      this.soloTypeLabel = this.data.booking.min_rider + ' - ' + this.data.booking.max_rider + ' Riders';
      this.getColumsTable();
      this.arrDates = this.data.dates;
      this.dateTour = this.data.booking.date;
      this.nameTour = this.data.booking.name;
      this.promotion = this.data.booking.promotion;
      this.typePrice = +this.data.booking.fix_price;

      if (this.data.booking.slug.indexOf('birthday') > -1) {
        this.isBirthday = true;
      } else {
        this.booked = +this.arrDates.filter((item) => item.fullDate == this.dateTour)[0].booked;
        this.isBirthday = false;
      }

      this.max = +this.data.booking.limit_rider;

      if (this.data.booking.limit_rider !== null) {
        this.col = 12;
      } else {
        this.col = 6;
      }

      this.filterRiders();
      if (this.data.totalPrice !== undefined) {
        this.totalPrice =
          (this.data.totalPrice.fullPrice * (100 - this.data.booking.code)) / 100;
        if (this.typePrice == 1) {
          this.fixPrice = +this.data.totalPrice.fixPrice;
        } else {
          this.fixPrice = +this.data.booking.price_normal;
        }
      } else {
        if (this.typePrice == 0) {
          this.totalPrice = 0;
        } else {
          this.totalPrice = +this.data.booking.price_normal;
          this.fixPrice = +this.data.booking.price_normal;
        }
      }

      if (this.data.riders !== undefined) {
        this.current = this.data.riders.filter(solo => solo.type == 'Solo').length;
        this.riders = this.data.riders;
        this.dataSource.data = this.riders;
      } else {
        this.current = 0;
        this.dataSource.data = [];
        this.riders = [];
      }

      if (this.isBirthday && this.riders.length > 7) {
        this.totalPrice = this.totalPrice - this.data.booking.price_normal;
      }
    } else {
      this._router.navigate(['/']);
    }
  }

  public back() {
    this._location.back();
  }

  public openDialog(element: any) {
    this.dialogRef = this._dialog.open(DialogComponent, {
      hasBackdrop: false
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(element);
      }
    });
  }

  public createForm() {
    this.formBooking = this._formBuilder.group({
      dates: [this.dateTour, [Validators.required]],
      promo: [this.promotion]
    });
  }

  public submit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.pushArr();
    }
  }

  public pushArr() {
    const __this = this;
    this._api.get('api/tours/old-email', __this.model.email).subscribe(res => {
      let price = 0;
      if (this.typePrice == 0) {
        if (__this.model.type == 'Solo') {
          __this.returnRider = +res;
        } else {
          __this.returnRider = 0;
        }
        switch (__this.model.type) {
          case 'Solo': price = ((100 - __this.returnRider) * +__this.data.booking.price_normal) / 100; break;
          case 'Bike': price = +__this.data.booking.price_driver; break;
          case 'Van': price = +__this.data.booking.price_nondriver; break;
          default: break;
        }
      } else {
        __this.returnRider = 0;
        this.fixPrice = +this.data.booking.price_normal;
        switch (__this.model.type) {
          case 'Solo': price = +this.data.booking.price_normal; break;
          case 'Bike': price = +__this.data.booking.price_driver; break;
          case 'Van': price = +__this.data.booking.price_nondriver; break;
          default: break;
        }
      }

      this.saveDataRider(__this, price);

    });
  }

  public saveDataRider(that, price) {
    let riderArr = [];
    riderArr = this.riders.map(item => item);
    riderArr.push(
      Object.assign({}, that.model, {
        id: Date.now(),
        fullname: that.model.firstname + ' ' + that.model.lastname,
        return_rider: that.returnRider,
        price: price
      })
    );
    let soloArr = riderArr.filter(solo => solo.type == 'Solo').length;
    let bikeArr = riderArr.filter(bike => bike.type == 'Bike').length;

    if (soloArr > (this.max - this.booked) && this.data.booking.limit_rider != null) {
      this.isLoading = false;
      this._toastr.warning("Fully booked can't add more solo - bike riders!");
    } else {
      if (soloArr < bikeArr) {
        this.isLoading = false;
        this._toastr.warning('Solo riders must be greater than bike passengers !');
      } else {
        if (soloArr > this.data.booking.max_rider && this.data.booking.max_rider !== null) {
          this.isLoading = false;
          this._toastr.warning('Solo riders must be greater than ' + this.data.booking.max_rider + ' riders!');
        } else {
          const rider = this.riders.filter((item: any) => item.email == this.model.email);
          if (rider.length == 0 || this.riders.length == 0) {
            if (this.model.type == 'Solo') {
              this.current = this.current + 1;
            }
            this.riders = riderArr;
            this.isLoading = false;
            this._toastr.success('Add Rider Successfully!');
            this.resetForm();
            this.saveRiders();
          } else {
            this.isLoading = false;
            this._toastr.warning('This email is used before!');
          }
        }
      }
    }
  }

  public resetForm() {
    this.form.reset();
    this.model = {};
    this.options.resetModel();
  }

  public edit(element: any) {
    this.model = Object.assign({}, element);
    this.showBtn = true;
  }

  public cancel() {
    this.model = {};
    this.showBtn = false;
  }

  public save() {
    const index = this.riders.findIndex(
      (item: any) => item.id === this.model.id
    );

    if (this.form.valid) {
      this.saveArr(index);
    }
  }

  public chooseDate(event) {
    if (this.data.booking.limit_rider) {
      let booked = +this.arrDates.filter(item => item.fullDate == event.value)[0].booked;
      let soloArr = this.riders.filter(solo => solo.type == 'Solo').length;

      if (soloArr > (this.max - booked)) {
        this._toastr.warning("Fully booked can't add more solo - bike riders!");
        this.createForm();
        return;
      } else {
        this.booked = booked;
        this.data.booking.date = event.value;
        sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
        this.current = soloArr;
      }
    } else {
      this.data.booking.date = event.value;
      sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
    }
  }

  public showSpaces(date) {
    let spaces = 0;
    if (this.data.booking.date == date.fullDate) {
      spaces = +this.data.booking.limit_rider - (+date.booked) - this.current;
    } else {
      spaces = +this.data.booking.limit_rider - (+date.booked);
    }

    if (spaces == 0) {
      return "<div class = 'dates'>" + date.fullDate + "<span class='full-spaces'>  (Fully booked)</span></div>";
    } else {
      return "<div class = 'dates'>" + date.fullDate + '  <span class="spaces">(' + spaces + ' spaces left)</span></div>';
    }
  }

  public saveArr(index) {
    const __this = this;
    this._api.get('api/tours/old-email', __this.model.email).subscribe(res => {
      __this.returnRider = +res;

      let price = 0;
      if (this.typePrice == 0) {
        if (__this.model.type == 'Solo') {
          __this.returnRider = +res;
        } else {
          __this.returnRider = 0;
        }
        switch (__this.model.type) {
          case 'Solo': price = ((100 - __this.returnRider) * +__this.data.booking.price_normal) / 100; break;
          case 'Bike': price = +__this.data.booking.price_driver; break;
          case 'Van': price = +__this.data.booking.price_nondriver; break;
          default: break;
        }
      } else {
        __this.returnRider = 0;
        this.fixPrice = +this.data.booking.price_normal;
        switch (__this.model.type) {
          case 'Solo': price = +__this.data.booking.price_normal; break;
          case 'Bike': price = +__this.data.booking.price_driver; break;
          case 'Van': price = +__this.data.booking.price_nondriver; break;
          default: break;
        }
      }
      let riderArr = [];
      riderArr = this.riders.map(item => item);

      riderArr[index] = Object.assign({}, this.model, {
        fullname: this.model.firstname + ' ' + this.model.lastname,
        price: price,
        return_rider: __this.returnRider
      });

      let soloArr = riderArr.filter(solo => solo.type == 'Solo').length;
      let bikeArr = riderArr.filter(bike => bike.type == 'Bike').length;

      if (soloArr > (this.max - this.booked) && this.data.booking.limit_rider != null) {
        this._toastr.warning("Fully booked can't add more solo - bike riders!");
      } else {
        if (soloArr < bikeArr) {
          this._toastr.warning('Solo Riders Must Be Greater Than Bike Passengers !');
        } else {
          const rider = this.riders.filter((item: any) => item.email == this.model.email);
          if (rider.length == 0 || this.riders.length == 0 || this.riders[index].email == this.model.email) {
            this.showBtn = false;
            this._toastr.success('Update Rider Successfully!');

            if (this.model.type == 'Solo' && this.riders[index].type != 'Solo') {
              this.current = this.current + 1;
            }

            if (this.riders[index].type == 'Solo' && this.model.type !== 'Solo') {
              this.current = this.current - 1;
            }

            this.riders = riderArr;
            this.saveRiders();
          }
          else {
            this._toastr.warning('This Email Is Used Before!');
          }
        }
      }
    });

  }

  public delete(element: any) {
    let soloArr = this.riders.filter(solo => solo.type == 'Solo').length;
    let bikeArr = this.riders.filter(bike => bike.type == 'Bike').length;

    if (soloArr == bikeArr && element.type == 'Solo') {
      if (this.typePrice == 0) {
        this._toastr.warning('Solo Riders Must Be Greater Than Bike Passengers!');
      } else {
        this._toastr.warning('Solo Riders Must Be Greater Than Bike Passengers!');
      }
    } else {
      this.riders = this.riders.filter((item: any) => item.id !== element.id);

      if (this.riders.length === 0) {
        this.isPayment = false;
      }
      this.saveRiders();
      if (element.type == 'Solo') {
        this.current = this.current - 1;
      }

      if (this.showBtn == true) {
        this.showBtn = false;
      }
      this._toastr.success('Delete Rider Successfully!');
    }
  }

  public saveRiders() {
    this.dataSource.data = this.riders;
    Object.assign(this.data, {
      riders: this.riders
    });
    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
    this.saveData();
    this.resetForm();
  }

  public continue() {
    if (this.formBooking.valid) {
      if (this.data.booking.type_tour == 1) {
        this.data.booking.date = this.formBooking.value.dates;
        sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
      }
      this.checkNumberRiders();
      if (this.isPayment == true) {

        this.turnOffBlur = true;
        if (this.formBooking.value.promo !== this.data.booking.promotion) {
          this.checkPromo(this.formBooking.value.promo, true);
        } else {
          this._router.navigate(['/payment']);
        }
      }
    }
  }

  public checkNumberRiders() {
    if (this.riders.length == 0) {
      this.isPayment = false;
      this._toastr.warning('Please Add A New Rider!');
    } else {
      let solo = this.riders.filter(item => item.type == 'Solo').length;
      let minRider = +this.data.booking.min_rider;
      let maxRider = +this.data.booking.max_rider;

      if (minRider == 0 && maxRider !== 0) {
        if (solo > maxRider) {
          this.isPayment = false;
          this._toastr.warning('Solo Riders Must Be Less Than ' + this.data.booking.max_rider + ' Riders!');
        } else {
          this.isPayment = true;
        }
      }

      if (minRider !== 0 && maxRider == 0) {
        if (solo < minRider) {
          this.isPayment = false;
          this._toastr.warning('Solo Riders Must Be Greater Than ' + this.data.booking.min_rider + ' Riders!');
        } else {
          this.isPayment = true;
        }
      }

      if (minRider == 0 && maxRider == 0) {
        this.isPayment = true;
      }

      if (minRider !== 0 && maxRider !== 0) {
        if (solo < minRider || solo > maxRider) {
          this.isPayment = false;
          this._toastr.warning('Solo Riders Must Be From ' + this.data.booking.min_rider + ' To ' + this.data.booking.max_rider + ' Riders!');
        } else {
          this.isPayment = true;
        }
      }
    }
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  public onBlur(value) {
    setTimeout(() => {
      if (this.turnOffBlur || value === this.data.booking.promotion) {
        return;
      }
      this.checkPromo(value);
    }, 300);
  }

  public checkPromo(value: string, navigate: boolean = false) {
    this.promotion = value;
    const promotion = {
      id: this.data.booking.id,
      code: value
    };

    if (value || value === this.data.booking.promotion) {
      this._api.post(this.apiPromotion, promotion).subscribe((res: any) => {
        const code = parseInt(res, 10);
        this.savePrice(code, value);
        if (navigate) {
          this._router.navigate(['/payment']);
        }
      });
    } else {
      this.invalidPromo();
      if (navigate) {
        this._router.navigate(['/payment']);
      }
    }
  }

  public savePrice(code: any, promotion: string) {
    if (code !== 0) {
      this._toastr.success('Sale: ' + code + '%');

      if (this.data.totalPrice !== undefined) {
        this.totalPrice = (this.data.totalPrice.fullPrice * (100 - code)) / 100;
        this.data.totalPrice.totalSale = this.totalPrice;
      }

      this.data.booking.errorPromotion = '';
      this.data.booking.code = code;
      this.data.booking.promotion = promotion;
      sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
    } else {
      if (this.data.booking.errorPromotion !== this.formBooking.value.promo) {
        this.invalidPromo();
      }
    }
  }

  public invalidPromo() {
    this.data.booking.code = '';
    this.data.booking.promotion = '';

    Object.assign(this.data.booking, {
      errorPromotion: this.formBooking.value.promo
    });

    if (this.data.totalPrice !== undefined) {
      this.data.totalPrice.totalSale = this.data.totalPrice.fullPrice;
      this.totalPrice = this.data.totalPrice.fullPrice;
    }

    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));

    if (this.formBooking.value.promo) {
      this._toastr.warning('Code Is Invalid!');
    }
  }

  public saveData() {
    this.countRider(this.data.riders);
  }

  public countRider(riders) {
    let solo = 0,
      bike = 0,
      van = 0;

    riders.forEach(rider => {
      switch (rider.type) {
        case 'Solo': solo += 1; break;
        case 'Bike': bike += 1; break;
        case 'Van': van += 1; break;
        default: break;
      }
    });

    return this.setTotalPrice({ solo, bike, van });
  }

  public setTotalPrice(typeRiders) {
    let total = 0;
    let priceRider = 0;
    if (this.typePrice == 0) {
      this.riders.forEach(rider => {
        total += rider.price;
      });
    } else {
      let riderArr = this.riders.filter((item: any) => item.type != 'Solo');
      riderArr.forEach(rider => {
        priceRider += (+rider.price);
      });
      total += (priceRider + this.fixPrice);
    }

    if (this.data.booking.code !== '') {
      this.totalPrice = (total * (100 - this.data.booking.code)) / 100;
    } else {
      this.totalPrice = total;
    }

    this.saveTotalPrice(typeRiders, total);
  }

  public saveTotalPrice(typeRiders, total: any) {
    if (this.isBirthday && this.riders.length >= this.data.booking.min_rider) {
      this.totalPrice = this.totalPrice - this.data.booking.price_normal;
      total = this.totalPrice;
    }

    const totalPrice = {
      bike: typeRiders.bike,
      rider: this.riders.length,
      solo: typeRiders.solo,
      fullPrice: total,
      totalSale: this.totalPrice,
      van: typeRiders.van,
      fixPrice: this.fixPrice
    };
    Object.assign(this.data, {
      totalPrice: totalPrice
    });
    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
  }

  public getTshirt() {
    this._api.get(this.getTshirtAPI).subscribe(tshirt => {
      this.tshirt = tshirt;
      this.fields[5].templateOptions.options = this.tshirt.map((item: any) => {
        return {
          label: item.name,
          value: item.name
        };
      });
    });
  }

  public filterRiders() {
    let typeRiders = [
      { label: this.soloTypeLabel, value: 'Solo', field: 'price_normal', type: '1' },
      { label: 'Solo Bike Rider', value: 'Solo', field: 'price_normal', type: '0' },
      { label: 'Bike Passenger', value: 'Bike', field: 'price_driver', type: 'both' },
      { label: 'Van Passenger', value: 'Van', field: 'price_nondriver', type: 'both' }
    ]

    if (this.typePrice == 0) {
      this.riderFields = typeRiders.filter((item: any) => +item.type !== 1);
    } else {
      this.riderFields = typeRiders.filter((item: any) => +item.type !== 0);
    }

    this.fields[3].templateOptions.options = this.riderFields.filter((item: any) => {
      if (+this.data.booking[item.field] > 0.00) {
        return true;
      }
      return false;
    });

  }
}
