import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements OnInit {

  constructor(public _setting: SettingsService) {
    this._setting.isShowFooter = false;
    this._setting.isShowHeader = false;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._setting.isShowFooter = true;
    this._setting.isShowHeader = true;
  }
}
