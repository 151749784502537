import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RidersService } from '@modules/checkout/riders/riders.service';
import { SettingsService } from '@services/settings.service';
import { MetaTagService } from '@services/meta.service';
import { TourService } from '@modules/tour/tour.service';
import * as moment from 'moment';

const DatepickerFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const DatepickerProviders = [
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: DatepickerFormats
  }
];

@Component({
  selector: 'app-riders',
  templateUrl: './riders.component.html',
  styleUrls: ['./riders.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: DatepickerProviders
})
export class RidersComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public minDate: any;

  constructor(
    public _riders: RidersService,
    public _setting: SettingsService,
    private _meta: MetaTagService,
    public _tour: TourService) {
    this._setting.isShowFooter = false;
    this._setting.isShowHeader = false;
    this._meta.set('checkout');
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.isLoading = true;
    }, 10);
    this.minDate = moment().format();
  }

  ngOnInit() {
    this._riders.set();
    this._riders.createForm();
  }

  birthdayDiscount() {
    const total: number = this._riders.totalPrice;
    const rider = parseInt(this._riders.data.booking.price_normal);
    return total + rider;
  }

  ngOnDestroy() {
    this._riders.form.reset();
    this._setting.isShowFooter = true;
    this._setting.isShowHeader = true;
  }
}
