import {Component, OnInit} from '@angular/core';

import {ApiService} from '@services/api.service';
import {SettingsService} from '@services/settings.service';
import {TourService} from '@modules/tour/tour.service';
import {MetaTagService} from '@services/meta.service';

@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit {
    public isLoading = false;

    public list: any;
    public tours = [];

    constructor(
        private _meta: MetaTagService,
        private _api: ApiService,
        private _setting: SettingsService,
        public _tour: TourService
    ) {
        window.scrollTo(0, 0);
        this._meta.set('tours');
    }

    ngOnInit() {
        this.isLoading = true;
        this._setting.loadScript(
            'https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=516&amp;locationId=12931053&amp;lang=en_AU&amp;rating=true&amp;nreviews=4&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=true&amp;border=false&amp;display_version=2'
        );

        this._api.get('api/tours/listcalendar').subscribe((list: any) => {
            this.list = list;
            Object.keys(this.list).forEach(key => {
                this.tours.push({key: key, value: this.list[key]});
            });
            this.isLoading = false;
        });
    }
}