import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  public get(endpoint: string, params: any = '') {
    const url = `/${endpoint}/${params}`;
    return this.http
      .get(url)
      .pipe(tap(value => `value = ${JSON.stringify(value)}`));
  }

  public post(endpoint: string, data: any) {
    const url = `/${endpoint}`;
    return this.http.post(url, data);
  }
}
