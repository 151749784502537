import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-reviews',
  templateUrl: './home-reviews.component.html',
  styleUrls: ['./home-reviews.component.scss']
})
export class HomeReviewsComponent implements OnInit {
  public stars = [1, 2, 3, 4, 5];
  public reviews = [];

  constructor() {}

  ngOnInit() {
    this.reviews = [
      {
        content:
          'Amazing adventures taking you off the beaten track...giving you a different experience of the culture...Touring with LBMT is a must on your bucket list.',
        author: 'Karen M',
        img: 'karen-m'
      },
      {
        content:
          "You can't go wrong with Lucky Bastards, from accommodation to daily activities, everything was spot on...Vietnam is one of those countries you must visit.",
        author: 'Michael B',
        img: 'micheal-b'
      },
      {
        content:
          'I cannot thank JJ and her team enough for making this trip a truly memorable one and I am looking forward to doing another ride with LBMT next year.',
        author: 'Donna & Tony',
        img: 'donna-tony'
      },
      {
        content:
          'We were looking for a trip of a lifetime through vietnam and man did we get that...These guys are the most amazing people I have ever met, our tour guides made this trip.',
        author: 'Jayne W',
        img: 'jayne-w'
      },
      {
        content:
          'I do not have enough words for this trip it was beyond my expectation and more. The tour was incredible and informative...I fell completely in love with Vietnam.',
        author: 'Simone C',
        img: 'simone-c'
      },
      {
        content:
          "Absolutely amazing bike adventure through Vietnam & Laos exploring both bustling cities & rural villages...Amazing group to tour with and experience I'll never forget!",
        author: 'Bredan M',
        img: 'brendan-m'
      }
    ];
  }
}
