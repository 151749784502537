import { FormlyFieldConfig } from '@ngx-formly/core';
import { validation } from '@models/validation';

export interface Payment {
  cardNumber: string;
  cvc: string;
  email: string;
  expiryMonth: string;
  expiryYear: string;
  phone: string;
  checked: number;
}

export const dataTable = [
  {
    matColumnDef: 'fullname',
    title: 'Name'
  },
  {
    matColumnDef: 'type',
    title: 'Type Rider'
  },
  {
    matColumnDef: 'return_rider',
    title: 'Return Rider'
  },
  {
    matColumnDef: 'price',
    title: 'Price'
  },
  {
    matColumnDef: 'phone_number',
    title: 'Phone'
  },
  {
    matColumnDef: 't_shirt',
    title: 'T-shirt'
  },
];

export const columnsFixPrice: string[] = [
  'fullname',
  'type',
  't_shirt',
  'price',
];

export const columnsNotFixPrice: string[] = [
  'fullname',
  'type',
  'return_rider',
  'price'
];
export const paymentModel: Payment = {
  cardNumber: '',
  cvc: '',
  email: '',
  expiryMonth: '',
  expiryYear: '',
  phone: '',
  checked: 1
};

export const paymentFields: FormlyFieldConfig[] = [
  {
    key: 'email',
    type: 'input',
    templateOptions: {
      type: 'text',
      label: 'Email',
      required: true
    },
    validators: {
      email: validation.email
    }
  },
  {
    key: 'phone',
    type: 'input',
    templateOptions: {
      type: 'text',
      label: 'Phone',
      required: true
    }
  },
  {
    key: 'cardNumber',
    type: 'input',
    templateOptions: {
      type: 'text',
      label: 'Card Number',
      required: true
    },
    validators: {
      cardNumber: validation.numberCard
    }
  },
  {
    fieldGroupClassName: 'display-flex',
    fieldGroup: [
      {
        className: 'flex-1',
        key: 'expiryMonth',
        type: 'select',
        templateOptions: {
          label: 'Expiry Month',
          options: [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '10', value: '10' },
            { label: '11', value: '11' },
            { label: '12', value: '12' }
          ],
          required: true
        }
      },
      {
        className: 'flex-1',
        key: 'expiryYear',
        type: 'select',
        templateOptions: {
          label: 'Expiry Year',
          options: [
            { label: '2018', value: '2018' },
            { label: '2019', value: '2019' },
            { label: '2020', value: '2020' },
            { label: '2021', value: '2021' },
            { label: '2022', value: '2022' },
            { label: '2023', value: '2023' },
            { label: '2024', value: '2024' },
            { label: '2025', value: '2025' }
          ],
          required: true
        }
      },
      {
        className: 'flex-1',
        key: 'cvc',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: 'CVC',
          required: true
        }
      }
    ]
  },
  {
    className: 'check-box',
    key: 'checked',
    type: 'checkbox',
    templateOptions: {
      label: 'Save and security card!'
    }
  },
  {
    className: 'des',
    template: '<p class="des">We do not directly save your card. For your safety, your card information is stored only by “Stripe.com”, one of the world’s largest payment management company.</p>'
  }
];
