import { Component, OnInit } from '@angular/core';
import { TourService } from '@modules/tour/tour.service';

@Component({
  selector: 'app-tour-hero',
  templateUrl: './tour-hero.component.html',
  styleUrls: ['./tour-hero.component.scss']
})
export class TourHeroComponent implements OnInit {
  constructor(public _tour: TourService) {}

  ngOnInit() {}
}
