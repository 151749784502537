import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { newTourFields } from '@modules/tour/list/new-tour-dialog/new-tour-dialog.model';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ApiService } from '@services/api.service';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Component({
  selector: 'app-new-tour-dialog',
  templateUrl: './new-tour-dialog.component.html',
  styleUrls: ['./new-tour-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewTourDialogComponent implements OnInit {
  public fields: FormlyFieldConfig[];
  public form: FormGroup;
  public model: any;
  public options: FormlyFormOptions;

  @ViewChild('cityInput') cityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  public start: any = '';
  public end: any = '';
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [COMMA];
  public cityCtrl = new FormControl();
  public filteredCities: Observable<string[]>;
  public cities: any[] = [];
  public allCities: any[] = [];
  public dateFormat: any = '';
  public arr = [];

  public isLoading: boolean;

  public apiSubmitForm = 'api/tours/sendcreatetour';

  constructor(
    public dialogRef: MatDialogRef<NewTourDialogComponent>,
    private _api: ApiService,
    private _toastr: ToastrService
  ) {
    this.fields = newTourFields;
    this.form = new FormGroup({});
    this.model = {};
    this.options = {};

    this.isLoading = false;
  }

  public getCity(character) {
    const cities = [];
    this._api.get('api/cities', '?q=' + character).subscribe((city) => {
      const data = city['data'];
      data.forEach(city => {
        cities.push({ name: city.name, id: city.id });
      });
      this.allCities = cities;

      this.filteredCities = this.cityCtrl.valueChanges.pipe(
        startWith(null),
        map((city: string | null) => city ? this._filter(city) : this.allCities.slice()));
    })
  }

  private _filter(value: any): any[] {

    return this.allCities.filter(city => city.id == value);
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.cities.push({ name: event.option.viewValue, id: event.option.value });
    this.cityInput.nativeElement.value = '';
    this.cityCtrl.setValue(null);
  }

  public remove(city: string): void {
    const index = this.cities.indexOf(city);

    if (index >= 0) {
      this.cities.splice(index, 1);
    }
  }

  public changeCity(e) {
    if (e.target.value !== '') {
      this.getCity(e.target.value);
    } else {
      return;
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public create() {
    if (this.model.start_date < moment().format()) {
      this.form.controls['start_date'].setErrors({ startDate: true });
    }

    if (this.model.end_date <= this.model.start_date) {
      this.form.controls['end_date'].setErrors({ endDate: true });
    }

    if (this.form.valid) {
      const data = Object.assign(this.model, {
        cities: this.cities
      })

      this.isLoading = true;
      this._api.post(this.apiSubmitForm, this.model).subscribe((res: any) => {
        if ((res = true)) {
          this.isLoading = false;
          this.resetForm();
          this._toastr.success('Create New Tour Successfully!');
          this.dialogRef.close();
        } else {
          this._toastr.success('Create New Tour Failed!');
        }
      });
    }
  }

  public resetForm() {
    this.form.reset();
    this.model = {};
    this.options.resetModel();
  }

  ngOnInit() { }
}
