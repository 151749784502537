import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Page} from '@modules/page/page.model';

import {PageService} from '@modules/page/page.service';
import {MetaTagService} from '@services/meta.service';
import {ApiService} from '@services/api.service';
import {SettingsService} from '@services/settings.service';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
    @ViewChild('dataContent')
    dataContent: ElementRef;
    private request: any;
    public content: any;
    public data: Page;
    public slug: string;

    public isContact = false;
    public isLoading = false;

    constructor(
        private _meta: MetaTagService,
        private route: ActivatedRoute,
        private _router: Router,
        public _api: ApiService,
        public _page: PageService,
        public _setting: SettingsService
    ) {
        window.scrollTo(0, 0);
        this.slug = this.route.snapshot.params.name;
        this._meta.set(this.slug);
        this._page.routes();
    }

    ngOnInit() {
        this.isLoading = true;
        this._setting.loadScript(
            'https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=516&amp;locationId=12931053&amp;lang=en_AU&amp;rating=true&amp;nreviews=4&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=true&amp;border=false&amp;display_version=2'
        );
        this.getPage();
    }

    ngOnDestroy() {
        this.request.unsubscribe();
    }

    private getPage() {
        this.request = this._api
            .get('api/pages', this.slug)
            .subscribe((res: any) => {
                if (!res.id) {
                    this._router.navigate(['/404']);
                } else {
                    this.data = res;
                    this.loadData(this.data.content);
                    this.content = res.content;
                    this.isLoading = false;
                }
            });

        if (this.slug === 'contact') {
            this.isContact = true;
        } else {
            this.isContact = false;
        }
    }

    public loadData(data) {
        setTimeout(() => {
            this.dataContent.nativeElement.innerHTML = data;
        }, 200);
    }
}
