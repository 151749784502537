import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { TourService } from '@modules/tour/tour.service';
import { ApiService } from '@services/api.service';
import { SettingsService } from '@services/settings.service';

@Injectable()
export class TourDetailService {
  public code: number;
  public data: any;
  public dateTour: any;
  public promotion: string;
  public tour: any;

  public formBooking: FormGroup;
  public priceTypeRiders: any[];

  public apiPromotion = 'api/tours/checkpromotioncode';

  constructor(
    @Inject(LOCAL_STORAGE) private _storage: WebStorageService,
    private _api: ApiService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastr: ToastrService,
    public _setting: SettingsService,
    public _tour: TourService
  ) {
    this.dateTour = '';
    this.promotion = '';

    this.priceTypeRiders = [
      {
        type: 'Solo Bike Riders',
        price: 'price_normal'
      },
      {
        type: 'Bike Passengers',
        price: 'price_driver'
      },
      {
        type: 'Van Passengers',
        price: 'price_nondriver'
      }
    ];
  }

  public init() {
    this.data = this._storage.get(this._setting.idTab);
    if (this.data === null || this.data.booking.date === undefined) {
      this.promotion = '';
      this.dateTour = '';
    } else {
      this.dateTour = this.data.booking.date;
      this.promotion = this.data.booking.promotion;
    }
  }

  public createForm() {
    this.formBooking = this._formBuilder.group({
      dates: [this.dateTour, [Validators.required]],
      code: [this.promotion]
    });
  }

  public onSubmitBooking() {
    if (this.formBooking.valid) {
      this.checkPromo();
    }
  }

  public checkPromo() {
    this.code = 0;
    this.data = this._storage.get(this._setting.idTab);
    this.tour = this.data.booking;

    const promotion = {
      id: this.tour.id,
      code: this.formBooking.value.code
    };

    if (this.formBooking.value.code) {
      return this._api
        .post(this.apiPromotion, promotion)
        .subscribe((res: any) => {
          this.code = parseInt(res, 10);
          if (this.code !== 0) {
            this.saveData(this.code, this.formBooking.value.code);
            this._router.navigate(['/riders']);
          } else {
            this.saveData('', '');
            this._router.navigate(['/riders']);
            this._toastr.warning('Code is invalid!');
          }
        });
    }
    this.saveData(this.code, this.formBooking.value.code);
    this._router.navigate(['/riders']);
  }

  public saveData(code: any, promo: any) {
    if (code) {
      this._toastr.success('Sale: ' + code + '%');
    }

    this.tour = Object.assign(this.tour, {
      code: code || '',
      min_rider: this._tour.data.min_rider,
      max_rider: this._tour.data.max_rider,
      date: this.formBooking.value.dates,
      price_driver: this._tour.data.price_driver,
      price_nondriver: this._tour.data.price_nondriver,
      price_normal: this._tour.data.price_normal,
      promotion: promo,
      type_tour: this._tour.data.type_tour,
      fix_price: this._tour.data.fix_price,
      limit_rider: this._tour.data.limit_rider
    });

    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));

    Object.assign(this.data, {
      dates: this._tour.dates 
    });
    sessionStorage.setItem(this._setting.idTab, JSON.stringify(this.data));
  }
}
