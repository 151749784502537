import { Component, OnInit } from '@angular/core';

import { ContactService } from '@modules/page/contact/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor(public _contact: ContactService) {}

  ngOnInit() {
    this._contact.set();
  }
}
