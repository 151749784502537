import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class SettingsService {
  public isShowFooter: boolean;
  public isShowHeader: boolean;
  public idTab: any;

  constructor() {
    this.isShowFooter = true;
    this.isShowHeader = true;
  }

  public loadScript(script) {
    const node = document.createElement('script');
    node.src = script;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public reloadPage() {
    window.onbeforeunload = () => {
     sessionStorage.setItem('isReload', this.idTab);
    };
  }

  public checkIdTab() {
    if (sessionStorage.getItem('isReload')) {
      this.idTab = sessionStorage.getItem('isReload');
      sessionStorage.removeItem('isReload');
    } else {
      this.idTab = 'tab-' +  moment().valueOf().toString();
    }

    this.reloadPage();
  }
}
