import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-birthday',
  templateUrl: './home-birthday.component.html',
  styleUrls: ['./home-birthday.component.scss']
})
export class HomeBirthdayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
