import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { SettingsService } from '@services/settings.service';
import { PaymentService } from '@modules/checkout/payment/payment.service';
import { MetaTagService } from '@services/meta.service';

import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogComponent } from '@components/dialog/dialog.component';
import { PaymentDialogComponent } from '@modules/tour/booked/payment-dialog/payment-dialog.component';
import { columnsFixPrice, columnsNotFixPrice } from '@modules/checkout/payment/payment.model';

@Component({
  selector: 'app-booked',
  templateUrl: './booked.component.html',
  styleUrls: ['./booked.component.scss']
})
export class BookedComponent implements OnInit, OnDestroy {
  public cancelRef: MatDialogRef<DialogComponent>;
  public paymentRef: MatDialogRef<PaymentDialogComponent>;
  @ViewChild('description') description: ElementRef;
  public action: string;
  public data: any;

  public dataSource = [];
  public dataTable = [];
  public displayedColumns = [];

  public fullName: string;
  public id: any;
  public remaining: any;
  public slug: any;
  public stats: Array<any>;
  public status: string;
  public other: number;
  public payType: string;
  public brand: string;
  public cardLast: number;
  public fixPrice: number;

  public isLoading = false;
  public isShowBtn: boolean;
  public isShowDeposit: boolean;

  constructor(
    private _api: ApiService,
    private _dialog: MatDialog,
    private _meta: MetaTagService,
    private _toastr: ToastrService,
    public _payment: PaymentService,
    public _route: ActivatedRoute,
    public _router: Router,
    public _setting: SettingsService
  ) {
    this._setting.isShowFooter = false;
    this._setting.isShowHeader = false;

    this.id = this._route.snapshot.params.id;
    this.slug = this._route.snapshot.params.slug;
    this.action = this._route.snapshot.queryParams['action'];

    this.isShowBtn = true;
    this.isShowDeposit = true;

    this.dataTable = [
      {
        matColumnDef: 'fullname',
        title: 'Name'
      },
      {
        matColumnDef: 'type',
        title: 'Type Rider'
      },
      {
        matColumnDef: 'return_rider',
        title: 'Return Rider'
      },
      {
        matColumnDef: 'price',
        title: 'Price'
      },
      {
        matColumnDef: 'phone_number',
        title: 'Phone'
      },
      {
        matColumnDef: 't_shirt',
        title: 'T-shirt'
      },
    ];

    const columnsFixPrice: string[] = [
      'fullname',
      'type',
      't_shirt',
      'price'
    ];

    const columnsNotFixPrice: string[] = [
      'fullname',
      'type',
      'return_rider',
      'price'
    ];

    switch (this.action) {
      case 'cancel':
        this.get(null);
        this.openCancelDialog();
        break;
      case 'pay':
        this.get('pay');
        break;
      default: this.get(this.action);
    }

    this._meta.set('home');
  }

  public get(action: string) {
    this._api.get('api/tours/detail-booked-tour', action ? this.id + '?action=' + action : this.id).subscribe((res: any) => {
      if (res.data !== false) {
        this.isLoading = true;
        if (res.data.tour.fix_price == 1) {
          this.displayedColumns = columnsFixPrice;
        } else {
          this.displayedColumns = columnsNotFixPrice;
        }
        this.other = res.data.book.other;
        this.brand = res.data.book.brand;
        this.cardLast = res.data.book.last4;
        this.other = +res.data.book.other;
        this.fixPrice = +res.data.tour.fix_price;

        switch (parseInt(res.data.book.status, 10)) {
          case 1: this.status = 'Pending'; break;
          case 2: this.status = 'Complete'; break;
          case 3: this.status = 'Cancel'; break;
          default: break;
        }

        switch (action == 'pay' && +res.data.book.status) {
          case 2:
            this._toastr.warning('You Paid Completely Before!');
            break;
          case 3: this._toastr.warning('This Tour Is Cancelled!'); break;
          case 1: this.openPaymentDialog();
        }

        switch (parseInt(res.data.book.pay_type, 10)) {
          case 1: this.payType = 'Full Price'; break;
          case 0: this.payType = 'Deposit ' + res.data.book.deposit_percent + '%'; break;
          default: break;
        }

        if (action == 'cancel') {
          if (res.action.status == 400) {
            this._toastr.warning(res.action.message);
          } else {
            this._toastr.success(res.action.message);
          }
        }

        this.stats = [
          { label: 'Days', icon: 'date_range', stat: res.data.tour.duration },
          { label: 'Cities', icon: 'location_city', stat: res.data.tour.cities },
          { label: 'KM', icon: 'motorcycle', stat: res.data.tour.distance }
        ];


        let hiddenDeparture = true;
        let hiddenArrival = true;
        if (res.data.tour.departure_airport) {
          hiddenDeparture = false
        }

        if (res.data.tour.arrival_airport) {
          hiddenArrival = false
        }

        this.stats = [
          { label: 'Days', icon: 'date_range', stat: res.data.tour.duration, hidden: false },
          { label: 'Cities', icon: 'location_city', stat: res.data.tour.cities, hidden: false },
          { label: 'KM', icon: 'motorcycle', stat: res.data.tour.distance, hidden: false },
          { label: '', icon: 'flight_takeoff', stat: res.data.tour.departure_airport, hidden: hiddenArrival },
          { label: '', icon: 'flight_land', stat: res.data.tour.arrival_airport, hidden: hiddenArrival }
        ];

        this.description.nativeElement.innerHTML = res.data.tour.description;

        if (res.data.book.status == 3 || res.data.book.status == 2) {
          this.isShowBtn = false;
        } else {
          this.isShowBtn = true;
          this.remaining = res.data.book.full_price - res.data.book.deposit;
        }

        if (res.data.book.status == 2) {
          this.isShowDeposit = false;
        }

        this.data = res.data;
        res.data.rider.forEach(rider => {
          this.fullName = rider.firstname + ' ' + rider.lastname;
          Object.assign(rider, {
            fullname: this.fullName
          });
        });

        this.dataSource = res.data.rider;

      } else {
        this._router.navigate(['/404']);
      }
    });
  }

  public openCancelDialog() {
    this.cancelRef = this._dialog.open(DialogComponent, {
      hasBackdrop: false
    });
    this.cancelRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = false;
        this._router.navigate(['/book', this.id, this.slug], { queryParams: { action: 'cancel' } });
        this.get('cancel');
      }
    });
  }

  public close() {
    this.paymentRef.close();
  }

  public openPaymentDialog() {
    this.isLoading = true;
    this.paymentRef = this._dialog.open(PaymentDialogComponent, {
      data: {
        id: this.id,
        slug: this.slug,
        other: this.other,
        brand: this.brand,
        cardLast: this.cardLast
      },
      disableClose: true
    });
  }

  public payRemaining(other: number) {
    this.isLoading = false;
    this._router.navigate(['/book', this.id, this.slug], { queryParams: { action: 'pay' } });
    this.openPaymentDialog();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._setting.isShowFooter = true;
    this._setting.isShowHeader = true;
  }
}
