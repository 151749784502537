import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { DateAdapter } from '@angular/material';

import { ApiService } from '@services/api.service';
import { MetaTagService } from '@services/meta.service';
import { SettingsService } from '@services/settings.service';
import { TourService } from '@modules/tour/tour.service';
import { HomeHeroService } from '@modules/home/hero/home-hero.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TourListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public list: any;
  public months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
  ];
  public selectedMonths = [];
  public searchMonths = '';

  constructor(
    private route: ActivatedRoute,
    private _api: ApiService,
    private _meta: MetaTagService,
    public _setting: SettingsService,
    public _tour: TourService,
    private adapter: DateAdapter<any>,
    public _heroService: HomeHeroService
  ) {
    this.adapter.setLocale('en-US');
    window.scrollTo(0, 0);
    this.list = [];
    this._meta.set('tours');
  }

  ngOnInit() {
    const months = this.route.snapshot.queryParams['months'];
    if (months) {
      this.searchMonths = months;
      this.search(true);
      this.searchMonths = '';
    } else {
      this.search();
    }
    this._setting.loadScript(
      'https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=516&amp;locationId=12931053&amp;lang=en_AU&amp;rating=true&amp;nreviews=4&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=true&amp;border=false&amp;display_version=2'
    );
  }

  public search(params = false) {
    const param = this.searchMonths !== '' ? `?months=${this.searchMonths}` : '';
    this.isLoading = true;
    this._api.get('api/tours/tours', param).subscribe(list => {
      this.list = list === 'No data' ? [] : list;
      this.isLoading = false;
      if (!params) {
        window.history.replaceState({}, document.title, '/tours');
      }
    });
  }

  public chooseMonths() {
    this.searchMonths = '';
    if (this.selectedMonths.indexOf('ALL') < 0) {
      const selected = [];
      this.selectedMonths.forEach((month: string) => {
        selected.push(month.substring(0, 3));
      });
      this.searchMonths = selected.join(',');
    } else {
      this.searchMonths = '';
      this.selectedMonths = ['ALL'];
    }
  }

  public allMonthsSelected() {
    return this.selectedMonths.indexOf('ALL') > -1;
  }

  ngOnDestroy() { }
}
