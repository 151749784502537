import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule,  } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { ComponentsModule } from '@components/components.module';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

import {
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatRadioModule,
  MatTableModule,
  MatSelectModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule
} from '@angular/material';

import { RidersComponent } from '@modules/checkout/riders/riders.component';
import { PaymentComponent } from '@modules/checkout/payment/payment.component';

import { RidersService } from '@modules/checkout/riders/riders.service';
import { PaymentService } from '@modules/checkout/payment/payment.service';
import { DialogComponent } from './payment/dialog/dialog.component';

@NgModule({
  imports: [
    RouterModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    CommonModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,

    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule
  ],
  declarations: [RidersComponent, PaymentComponent, DialogComponent],
  entryComponents: [DialogComponent],
  providers: [RidersService, PaymentService]
})
export class CheckoutModule {}
