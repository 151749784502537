import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StorageServiceModule } from 'angular-webstorage-service';
import { ToastrModule } from 'ngx-toastr';
import { appRoutes } from './app.routes';
import { CheckoutModule } from '@modules/checkout/checkout.module';
import { ComponentsModule } from '@components/components.module';
import { HomeModule } from '@modules/home/home.module';
import { PageModule } from '@modules/page/page.module';
import { TourModule } from '@modules/tour/tour.module';

import { AppComponent } from './app.component';

import '../style.scss';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'lbmt' }),
    CheckoutModule,
    CommonModule,
    ComponentsModule,
    HomeModule,
    HttpClientModule,
    HttpModule,
    PageModule,
    RouterModule.forRoot(appRoutes),
    StorageServiceModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    TourModule
  ],
  providers: [],

  bootstrap: [AppComponent]
})
export class AppModule {}
