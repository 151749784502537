import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  OnChanges
} from '@angular/core';
import { TourService } from '@modules/tour/tour.service';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-tour-gallery',
  templateUrl: './tour-gallery.component.html',
  styleUrls: ['./tour-gallery.component.scss']
})
export class TourGalleryComponent implements OnChanges, OnInit {
  @ViewChild('dataContent') dataContent: ElementRef;
  @Input() content: any;

  public lightbox: any;
  public isShowLightBox: boolean;
  public values = '';
  public value = 0;

  constructor(public _tour: TourService) {
    window.scrollTo(0, 0);
    this.setLightbox('', '0', false);
    this.isShowLightBox = false;
  }

  ngOnChanges(change: any) {
    if (change.content) {
      this.loadData(this.content);
    }
  }
  public loadData(data) {
    setTimeout(() => {
      this.dataContent.nativeElement.innerHTML = data;
    }, 200);
  }

  ngOnInit() {}

  public setLightbox(img: string, index: any, isShown = true) {
    this.isShowLightBox  = true;
    this.lightbox = {
      isShown: isShown,
      index: index,
      image: img
    };
    if (img == '' && index == 0 && isShown == false) {
      this.isShowLightBox  = false;
    }
  }

  public lightboxNav(dir = 1) {
    const imgs = this._tour.data.images;
    const imgCount = imgs.length;
    let index = this.lightbox.index;

    if (dir === 1) {
      index = index + 1 === imgCount ? 0 : index + 1;
    }

    if (dir === 0) {
      index = index === 0 ? imgCount - 1 : index - 1;
    }

    this.setLightbox(imgs[index], index);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW && this.isShowLightBox == true) {
      this.lightboxNav(1);
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW && this.isShowLightBox == true) {
      this.lightboxNav(0);
    }
  }
}
