import { FormlyFieldConfig } from '@ngx-formly/core';
import { validation } from '@models/validation';

export interface Contact {
  email: string;
  message: string;
  name: string;
  phone: string;
}

export const contactModel = {
  email: null,
  message: null,
  name: null,
  phone: null
};

export const contactFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    templateOptions: {
      type: 'text',
      label: 'Name',
      required: true
    }
  },
  {
    key: 'email',
    type: 'input',
    templateOptions: {
      type: 'email',
      label: 'Email',
      required: true
    },
    validators: {
      email: validation.email
    }
  },
  {
    key: 'phone',
    type: 'input',
    templateOptions: {
      type: 'tel',
      label: 'Phone'
    }
  },
  {
    key: 'message',
    type: 'textarea',
    templateOptions: {
      label: 'Message'
    }
  }
];
