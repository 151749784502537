import { Routes } from '@angular/router';

import { HomeComponent } from '@modules/home/home.component';
import { PageComponent } from '@modules/page/page.component';
import { PaymentComponent } from '@modules/checkout/payment/payment.component';
import { RidersComponent } from '@modules/checkout/riders/riders.component';
import { TourDetailComponent } from '@modules/tour/detail/tour-detail.component';
import { TourListComponent } from '@modules/tour/list/tour-list.component';
import { CalendarViewComponent } from '@modules/tour/calendar-view/calendar-view.component';
import { BookedComponent } from '@modules/tour/booked/booked.component';
import { NotFoundComponent } from '@modules/page/not-found/not-found.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'tours',
    component: TourListComponent
  },
  {
    path: 'book/:id/:slug',
    component: BookedComponent
  },
  // {
  //   path: 'calendar',
  //   component: CalendarViewComponent
  // },
  {
    path: 'tours/:id/:slug',
    component: TourDetailComponent
  },

  {
    path: 'page/:name',
    component: PageComponent
  },
  {
    path: 'riders',
    component: RidersComponent
  },
  {
    path: 'payment',
    component: PaymentComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];
