import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { ComponentsModule } from '@components/components.module';

import { TourService } from '@modules/tour/tour.service';
import { TourDetailService } from '@modules/tour/detail/tour-detail.service';

import { TourListComponent } from '@modules/tour/list/tour-list.component';
import { TourDetailComponent } from '@modules/tour/detail/tour-detail.component';
import { TourHeroComponent } from '@modules/tour/detail/hero/tour-hero.component';

import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatTooltipModule,
  MatTableModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatAutocompleteModule
} from '@angular/material';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { TourDatesComponent } from '@modules/tour/detail/dates/tour-dates.component';
import { TourBookingBarComponent } from '@modules/tour/detail/booking-bar/tour-booking-bar.component';
import { TourGalleryComponent } from '@modules/tour/detail/gallery/tour-gallery.component';
import { NewTourDialogComponent } from '@modules/tour/list/new-tour-dialog/new-tour-dialog.component';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';

import { ApiService } from '@services/api.service';
import { BookedComponent } from './booked/booked.component';
import { PaymentDialogComponent } from './booked/payment-dialog/payment-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,

    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    SatDatepickerModule,
    SatNativeDateModule,

    FormlyModule.forRoot(),
    FormlyMaterialModule,
  ],
  exports: [NewTourDialogComponent],
  providers: [TourService, ApiService, TourDetailService, MatDatepickerModule],
  entryComponents: [NewTourDialogComponent, PaymentDialogComponent],
  declarations: [
    TourListComponent,
    TourDetailComponent,
    TourHeroComponent,
    TourDatesComponent,
    TourBookingBarComponent,
    TourGalleryComponent,
    NewTourDialogComponent,
    CalendarViewComponent,
    BookedComponent,
    PaymentDialogComponent
  ]
})
export class TourModule { }
