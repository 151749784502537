import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '@services/api.service';

@Injectable()
export class PageService {
  public showFacebook: boolean = false;
  public showInstagram: boolean = false;
  public facebookLink: any;
  public instagram: any;

  constructor(
    private router: Router,
    public _api: ApiService ) {
      this.getFacebookLink();
      this.getInstagram();
    }

  public routes() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  public getFacebookLink() {
    this._api.get('api/data/setting/facebook').subscribe(res => {
      if (res) {
        this.facebookLink = res;
        this.showFacebook = true
      } else {
        this.showFacebook = false;
      }
    })
  }

  public getInstagram() {
    this._api.get('api/data/setting/instagram').subscribe(res => {
      if (res) {
        this.instagram = res;
        this.showInstagram = true
      } else {
        this.showInstagram = false;
      }
    })
  }
}
