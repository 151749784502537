import { FormlyFieldConfig } from '@ngx-formly/core';

const regex = (regexCode, message) => {
  return {
    expression: c => regexCode.test(c.value),
    message: (error, field: FormlyFieldConfig) => `Not a valid ${message}`
  };
};

const required = () => {
  return 'This field is required!';
};

const numberCard = regexCode => {
  return {
    expression: c => {
      if (c.value && c.value.length === 19) {
        return true;
      }
      return false;
    },
    message: (error, field: FormlyFieldConfig) => `Not a valid number card`
  };
};

const date = message => {
  return {
    expression: c => {
      return true;
    },
    message: (error, field: FormlyFieldConfig) => `${message}`
  };
};

export const validation = {
  required: required(),
  date: regex(/^(\d{2}\/\d{2}\/\d{4})$/, 'datetime'),
  siaBadge: regex(/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/, 'badge number'),
  drivingLicence: regex(
    /^([A-Z0-9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z0-9]{3}[A-Z]{2})$/,
    'driving licence number'
  ),

  postcode: regex(
    /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/,
    'postcode'
  ),

  email: regex(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/,
    'email'
  ),

  phone: regex(
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
    'contact number'
  ),

  niNumber: regex(
    /^[A-z]{2}\s?[\d]{2}\s?[\d]{2}\s?[\d]{2}\s?[A-z]{1}$/,
    'National Insurance number'
  ),

  password: regex(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, 'password'),
  numberCard: numberCard(/\D/),
  endDate: date('End date must be after start date!'),
  startDate: date('Start date must be after today!')
};
