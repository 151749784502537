import {Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

import {ApiService} from '@services/api.service';
import {SettingsService} from '@services/settings.service';
import {TourService} from '@modules/tour/tour.service';
import {TourDetailService} from '@modules/tour/detail/tour-detail.service';
import {MetaTagService} from '@services/meta.service';

@Component({
    selector: 'app-tour-detail',
    templateUrl: './tour-detail.component.html',
    styleUrls: ['./tour-detail.component.scss']
})
export class TourDetailComponent implements OnInit, OnDestroy {
    @ViewChild('description') description: ElementRef;
    public element: any;
    public isLoading = false;
    public request;
    public data: any;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: WebStorageService,
        private _api: ApiService,
        private _meta: MetaTagService,
        private _route: ActivatedRoute,
        private _router: Router,
        public _detail: TourDetailService,
        public _setting: SettingsService,
        public _tour: TourService,
    ) {
    }

    ngOnInit() {
        this.get();
    }

    ngOnDestroy() {
        this.request.unsubscribe();
    }

    private get() {
        this.isLoading = true;
        const id = +this._route.snapshot.paramMap.get('id');

        this.request = this._api
            .get('api/tours/detailtour', id)
            .subscribe(
                (res: any) => {
                    if (res == 'No data') {
                        this._router.navigate(['/404']);
                    } else {
                        const data = {
                            id: res.item_id,
                            name: res.item.title,
                            slug: res.item.slug
                        };

                        this.description.nativeElement.innerHTML = res.item.description;
                        this.element = this.description.nativeElement.textContent;

                        this._meta.setPage({
                            title: res.item.title,
                            description: this.element,
                            image: res.item.image,
                            keywords: 'LBMT, Tour, Travel'
                        });

                        this.saveData(id, data);
                        this.isLoading = false;
                        this._tour.init(res);
                    }
                });
    }

    public saveData(id: any, data: any) {
        this.data = this.storage.get(this._setting.idTab);

        if (this.data === null || this.data.booking.id != id) {
            this._detail.promotion = '';
            this._detail.dateTour = '';

            this._detail.createForm();

            this.storage.set(this._setting.idTab, {
                booking: data
            });
        } else {
            this._detail.promotion = this.data.booking.promotion;
            this._detail.dateTour = this.data.booking.date;
        }
    }
}
