import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material';

import { Router } from '@angular/router';
import { SettingsService } from '@services/settings.service';
import { ApiService } from '@services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeHeroService {
  public isLoading = false;
  public months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
  ];
  public selectedMonths = [];
  public searchMonths = '';

  constructor(
    private adapter: DateAdapter<any>,
    public _api: ApiService,
    public _setting: SettingsService,
    private router: Router
  ) {
    this.adapter.setLocale('en-US');
    window.scrollTo(0, 0);
  }

  public chooseMonths() {
    this.searchMonths = '';
    if (this.selectedMonths.indexOf('ALL') < 0) {
      const selected = [];
      this.selectedMonths.forEach((month: string) => {
        selected.push(month.substring(0, 3));
      });
      this.searchMonths = selected.join(',');
    } else {
      this.searchMonths = '';
      this.selectedMonths = ['ALL'];
    }
  }

  public allMonthsSelected() {
    return this.selectedMonths.indexOf('ALL') > -1;
  }

  public search() {
    this.router.navigate(['/tours'], {
      queryParams: {
        months: this.searchMonths
      }
    });
  }
}
